import React, { useState } from 'react';
import './App.css';
import Navbar from './layout/Navbar';
import Menu from './layout/Menu';
import Layout from './layout/Layout';

function App() {
    const [showMenu, setShowMenu] = useState(true);

    const checkMenu = () => {
        setShowMenu(!showMenu);
    };

    return (
        <div>
            <Navbar checkMenu={checkMenu} />
            <Menu showMenu={showMenu} />
            <Layout showMenu={showMenu} />
        </div>
    );
}

export default App;
