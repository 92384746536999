import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';

export default function Checkin() {
    const [checkins, setCheckins] = useState([]);
    const [activeClients, setActiveClients] = useState([]);
    const [clients, setClients] = useState({});
    const [filter, setFilter] = useState('');
    const [selectedClientId, setSelectedClientId] = useState(null);
    

    useEffect(() => {
        fetchCheckins();
        fetchClients();
        fetchActiveClients();
    }, []);

    const fetchCheckins = async () => {
        try {
            const response = await fetch('https://api.itonit.ro/api/gym/getCheckins');
            const data = await response.json();
            setCheckins(data.data || []);
        } catch (error) {
            console.error('Eroare la preluarea datelor de check-in:', error);
        }
    };

    const fetchClients = async () => {
        try {
            const response = await fetch(`https://api.itonit.ro/api/gym/getClients`);
            const data = await response.json();

            const clientsData = {};
            data.data.forEach((client) => {
                clientsData[client.id] = {
                    name: client.name,
                    surname: client.surname,
                };
            });

            setClients(clientsData);
        } catch (error) {
            console.error('Eroare la preluarea clinetilor din getClients', error);
        }
    };

    const fetchActiveClients = async () => {
        try {
            const response = await fetch('https://api.itonit.ro/api/gym/getActiveClients');
            const data = await response.json();
            
            setActiveClients(data.data || []);
        } catch (error) {
            console.error('Eroare la preluarea datelor de clienti:', error);
        }
    };

    const handleCheckout = async (id) => {
        try {
            await fetch(`https://api.itonit.ro/api/gym/setCheckout?id=${id}`, {
                method: 'POST',
            });
            fetchCheckins();
        } catch (error) {
            console.error('Eroare la efectuarea checkout-ului:', error);
        }
    };


    const handleSetCheckin = async () => {
        if (selectedClientId !== null) {
            try {
                await fetch('https://api.itonit.ro/api/gym/setCheckin', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ client_id: selectedClientId }),
                });
                fetchCheckins();
            } catch (error) {
                console.error('Eroare la efectuarea check-in-ului:', error);
            }
            
        } else {
            console.error('Nu a fost selectat niciun client.');
        }
        
    };

    const renderClientHeader = () => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '18px' }}>Client</span>
                <TextField
                    label="Search by Name"
                    variant="outlined"
                    value={filter}
                    style={{ width: '70%' }}
                    onChange={(e) => setFilter(e.target.value)}
                    size="small"
                />
            </div>
        );
    };

    

    const columns = [
        {
            field: "client_id",
            headerName: renderClientHeader(),
            flex: 1,
            sortable: false,
            sortDirection: false,
            renderCell: (params) => {
                const clientData = clients[params.value];
                if (clientData) {
                    return <span>{`${clientData.name} ${clientData.surname}`}</span>;
                } else {
                    return null;
                }
            },
        },
        {
            field: "start",
            headerName: 'Start',
            flex: 1,
        },
        {
            field: "stop",
            headerName: 'Stop',
            flex: 1,
            renderCell: (params) => {
                if (params.value === null) {
                    return (
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleCheckout(params.row.id)}
                        >
                            Checkout
                        </Button>
                    );
                } else {
                    return <span>{params.value}</span>;
                }
            },
        },
    ];



    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    maxWidth: 'calc(100% - 60px)',
                    margin: '0 auto',
                    padding: '10px 30px',
                }}
            >
                <Autocomplete
                    style={{ width: '250px' }}
                    options={activeClients.map(client => ({ id: client.id, name: `${client.name} ${client.surname}` }))}
                    getOptionLabel={(option) => `${option.name}`} 
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select a Client"
                            variant="outlined"
                            fullWidth
                        />
                    )}
                    onChange={(event, value) => {
                        if (value) {
                            setSelectedClientId(value.id);
                        } else {
                            setSelectedClientId(null);
                        }
                    }}
                />


                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSetCheckin()}
                >
                    Submit
                </Button>
            </Box>

            <div style={{ height: 'calc(100vh - 200px)', width: 'auto' }}>
                <DataGrid
                    columns={columns}
                    rows={checkins.map((row) => ({ id: row.id, start: row.start, stop: row.stop, client_id: row.client_id }))}


                /*   rows={checkins.filter((row) =>
                      activeClients[row.client_id]?.toLowerCase().includes(filter.toLowerCase())    !!! <== PENTRU A FUNCTIONA  ACEL SEARCH PE COLOANA 
                  )} */
                />
            </div>
        </div>
    );
}
