import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { useState, useEffect } from 'react';
import dayjs from "dayjs";
/* import MuiPhone from "./MUIPhone/MuiPhone";
import countryOptions from "./data.json"; */

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import "./PhoneStyle.css";






export default function UpsertClient(props) {

  /*   const [phoneNumber, setPhoneNumber] = useState("+18182925620");
    //const [phoneNumber, setPhoneNumber] = useState("+18182925620");

    const onPhoneChanged = (val) => {
        setPhoneNumber(val);
    };
 */
    const { isModalOpen, formData, setFormData, resetFormData, handleCloseModal, fetchData } = props
    const [phone, setPhone] = useState("");

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleDateChange = (event) => {

        setFormData({
            ...formData,
            subscription_start_date: event.format("YYYY-MM-DD"),
        });
    };


    const handlePhoneChange = (value) => {
        setPhone(value);
        setFormData({
            ...formData,
            phone: value,
        });
    };
    
    const handleSubmitModalEdit = async () => {
        if (!formData.name || !formData.surname || !formData.cnp || !formData.email || !formData.phone) {
            alert("Please fill in all required fields.");
            return;
        }

        if (formData.cnp.length !== 13 || !/^\d+$/.test(formData.cnp)) {
            alert("CNP must be a 13-digit number.");
            return;
        }

        if (!/\S+@\S+\.\S+/.test(formData.email)) {
            alert("Invalid email address.");
            return;
        }

        if (!/^\+?40[0-9]{9}$/.test(formData.phone)) {
            alert("Invalid phone number format. Please enter a 10-digit number starting with +40.");
            return;
        }

        try {
            const formDataToSend = new FormData();
            for (const key in formData) {
                if (formData.hasOwnProperty(key)) {
                    if (key === 'image' && formData[key].file) {
                        formDataToSend.append('photo', formData[key].file);
                    } else {
                        formDataToSend.append(key, formData[key]);
                    }
                }
            }

            const response = await fetch(`https://api.itonit.ro/api/gym/editClient`, {
                method: 'POST',
                body: formDataToSend,
            });

            if (response.ok) {
                console.log('Datele au fost actualizate cu succes');
                fetchData();
            } else {
                console.error('Eroare la actualizarea datelor');
            }
            resetFormData();
        } catch (error) {
            console.error('Eroare la actualizarea datelor:', error);
        }
        handleCloseModal();
    };

    const handleSubmitModalAdd = async () => {
        if (!formData.name || !formData.surname || !formData.cnp || !formData.email || !formData.phone) {
            alert("Please fill in all required fields.");
            return;
        }

        if (formData.cnp.length !== 13 || !/^\d+$/.test(formData.cnp)) {
            alert("CNP must be a 13-digit number.");
            return;
        }

        if (!/\S+@\S+\.\S+/.test(formData.email)) {
            alert("Invalid email address.");
            return;
        }

        if (!/^\+?40[0-9]{9}$/.test(formData.phone)) {
            alert("Invalid phone number format. Please enter a 10-digit number starting with +40.");
            return;
        }

        try {
            const formDataToSend = new FormData();
            for (const key in formData) {
                if (formData.hasOwnProperty(key)) {
                    if (key === 'image' && formData[key].file) {
                        formDataToSend.append('photo', formData[key].file);
                    } else {
                        formDataToSend.append(key, formData[key]);
                    }
                }
            }

            const response = await fetch(`https://api.itonit.ro/api/gym/addClient`, {
                method: 'POST',
                body: formDataToSend,
            });

            if (response.ok) {
                console.log('Datele au fost inserate cu succes');
                fetchData();
            } else {
                console.error('Eroare la inserare datelor');
            }
            resetFormData();
        } catch (error) {
            console.error('Eroare la inserare datelor:', error);
        }
        handleCloseModal();
    };


    const [subscriptionOptions, setSubscriptionOptions] = useState([]);

    useEffect(() => {
        fetchSubscriptionOptions();
        if (!formData.subscription_start_date) {
            setFormData({
                ...formData,
                subscription_start_date: dayjs().format("YYYY-MM-DD"),
            });
        }
    }, []);
    const fetchSubscriptionOptions = async () => {
        try {
            const response = await fetch('https://api.itonit.ro/api/gym/getTableSubscriptions');
            if (response.ok) {
                const data = await response.json();
                const options = data.data.map(item => ({
                    value: item.id.toString(),
                    label: item.name,
                }));
                setSubscriptionOptions(options);
            } else {
                console.error('Eroare la obținerea datelor de abonamente');
            }
        } catch (error) {
            console.error('Eroare la obținerea datelor de abonamente:', error);
        }
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFormData({
                    ...formData,
                    image: {
                        file: file,
                        preview: reader.result,
                    },
                });
            };
            reader.readAsDataURL(file);
        }
    };

   /*  function handleOnChange(value) {
        this.setState({
            phone: value
        });
    } */


    return (
        <Modal
            open={isModalOpen}
            onClose={handleCloseModal}
            aria-labelledby="add-client-modal"
            aria-describedby="add-client-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '800px',
                    height: '500px',
                    bgcolor: 'white',
                    padding: '20px',
                    borderRadius: '8px',
                }}
            >
                <h2 id="add-client-modal" style={{ textAlign: 'center' }}>{formData.id ? 'Editeaza' : 'Adauga'} Clienți</h2>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <TextField
                            name="name"
                            label="Nume"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            value={formData.name}
                            onChange={handleChange}
                            inputProps={{
                                type: 'text',
                                pattern: "[A-Za-z]*",
                            }}
                            onInput={(e) => {
                                e.target.value = e.target.value.replace(/[^A-Za-z]/g, '');
                            }}
                        />
                        <TextField
                            name="surname"
                            label="Prenume"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            value={formData.surname}
                            onChange={handleChange}
                            inputProps={{
                                type: 'text',
                                pattern: "[A-Za-z]*",
                            }}
                            onInput={(e) => {
                                e.target.value = e.target.value.replace(/[^A-Za-z]/g, '');
                            }}

                        />
                        <TextField
                            name="cnp"
                            label="CNP"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            value={formData.cnp}
                            onChange={handleChange}
                            inputProps={{
                                type: 'text',
                                pattern: "\\d*",
                            }}
                            onInput={(e) => {
                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                e.target.value = e.target.value.slice(0, 13);
                            }}
                        />
                        <TextField
                            name="email"
                            label="Email"
                            type="email"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        {/*       <Box style={{ width: "80%", maxWidth: "1200px" }}>
                            <MuiPhone
                                value={phoneNumber}
                                label="Phone Number"
                                countryOptions={countryOptions}
                                onChange={onPhoneChanged}
                            />
                        </Box> */}

                        {/*       React.render(
                        <MuiPhoneNumber defaultCountry={'us'} onChange={handleOnChange} />,
                        document.getElementById('root')
                        ); */}
                        <PhoneInput
                            country={'ro'}
                            value={formData.phone}
                            onChange={handlePhoneChange}
                            className="custom-phone-input"
                           />
                    </Grid>
                    <Grid item xs={6} >


                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Abonamente</InputLabel>
                            <Select
                                name="subscription_id"
                                label="Abonamente"
                                fullWidth
                                style={{ marginTop: '15px' }}
                                value={formData.subscription_id}
                                onChange={handleChange}
                            >
                                {subscriptionOptions.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {formData.id && (
                            <FormControl fullWidth variant="outlined">
                                <InputLabel>Status Client</InputLabel>
                                <Select
                                    name="status"
                                    label="Status Client"
                                    fullWidth
                                    style={{ marginTop: '15px' }}
                                    value={formData.status}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={1}>Activ</MenuItem>
                                    <MenuItem value={0}>Inactiv</MenuItem>
                                </Select>
                            </FormControl>)}



                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker
                                    label="Start Abonament"
                                    minDate={AdapterDayjs}
                                    value={dayjs(formData.subscription_start_date)}
                                    onChange={(newValue) => handleDateChange(newValue)}
                                />
                            </DemoContainer>
                        </LocalizationProvider>

                        <div>
                            <input
                                type="file"
                                id="fileInput"
                                accept="image/*"
                                value={formData.photo}
                                onChange={handleImageChange}
                            />
                            {formData.image && (
                                <img
                                    id="previewImage"
                                    src={formData.image.preview}
                                    alt="Preview"
                                    style={{ width: '100px', height: 'auto' }}
                                />
                            )}

                        </div>

                    </Grid>
                </Grid>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        maxWidth: 'calc(100% - 60px)',
                        margin: '0 auto',
                        padding: '30px 100px',
                    }}
                >
                    <Button variant="contained" color="secondary" onClick={handleCloseModal}>
                        Anuleaza
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={formData.id ? handleSubmitModalEdit : handleSubmitModalAdd}
                        sx={{ marginRight: '10px' }}
                    >
                        Salveaza
                    </Button>
                </Box>
            </Box>
        </Modal>
    );

}