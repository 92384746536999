import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { Route, Routes, Outlet } from 'react-router-dom';

import Clients from '../pages/Clients';
import Checkin from '../pages/Checkin';
import Subscriptions from '../pages/Subscriptions';
import Settings from '../pages/Settings';
import Login from '../pages/Login';
import Calendar from '../pages/Calendar';

export default function SimpleContainer(props) {
    const { showMenu } = props;
    const [menuWidth, setMenuWidth] = React.useState(showMenu ? '120px' : '0');
    const layoutWidth = showMenu ? '90%' : '100%';

    React.useEffect(() => {
        setMenuWidth(showMenu ? '120px' : '0');
    }, [showMenu]);

    return (
        <React.Fragment>
            <CssBaseline />
            <Container
                maxWidth="ls"
                style={{
                    marginLeft: menuWidth,
                    width: layoutWidth,
                    transition: 'margin-left 0.3s ease, width 0.3s ease',
                }}
            >
                <Routes>
                    <Route path="/checkin" element={<Checkin />} />
                    <Route path="/clients" element={<Clients />} />
                    <Route path="/subscriptions" element={<Subscriptions />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/calendar" element={<Calendar />} />
                    <Route path="/" element={<Login />} />
                </Routes>
            </Container>
            <Outlet />
        </React.Fragment>
    );
}
