import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import Button from "@mui/material/Button";
import { Stack } from "@mui/material";
import UpsertSubscription from '../components/upsertSubscription';
import DeleteSubscription from '../components/DeleteSubscription';




function Subscriptions() {
    const [clients, setClients] = useState([]);
    const [deleteId, setDeleteId] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem('isLoggedIn')) {
            navigate('/');
        } else {
            fetchData();
        }
    }, [navigate]);

    const columns = [
        { field: "name", headerName: 'Nume', flex: 1 },
        { field: "price", headerName: 'Pret', flex: 1 },
        { field: "days", headerName: 'Zile', flex: 1 },
        {
            field: 'stauts',
            headerName: 'Status ',
            flex: 1,
            renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={2}>
                        <p >{params.row.status === '1' ? "Acitv":"Inactiv" }</p>
                    </Stack>
                );
            },
        },
        { field: "couch_sessions", headerName: 'Zile cu Antrenor', flex: 1 },
        {
            field: 'action',
            headerName: 'Acțiune',
            flex: 1,
            sortable: false,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const onClickEdit = () => {
                    setFormData({
                        ...formData,
                        id: params.row.id,
                        name: params.row.name,
                        status: params.row.status,
                        price: params.row.price,
                        days: params.row.days,
                        couch_sessions: params.row.couch_sessions,
                    })
                    handleOpenModal();
                };

                return (
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" color="warning" size="small" onClick={onClickEdit}>Edit</Button>
                    </Stack>
                );
            },
        },
        {
            field: 'delete',
            headerName: 'Status',
            flex: 1,
            sortable: false,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const onClickDelete = () => {
                    setDeleteId(params.row.id)
                    handleAlertOpen()
                };


                return (
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" color="error" size="small" onClick={onClickDelete}>Delete</Button>
                    </Stack>
                );
            },
        },
    ];
    const [isAlertOpen, setIsAlertOpen] = useState(false);

    const handleAlertOpen = () => {
        setIsAlertOpen(true);
    }

    const handleCloseAlertOpen = () => {
        setIsAlertOpen(false);
    }


    const fetchData = async () => {
        try {
            const response = await fetch('https://api.itonit.ro/api/gym/getTableSubscriptions');
            const data = await response.json();
            setClients(data.data || []);
        } catch (error) {
            console.error('Eroare la preluarea datelor:', error);
        }
    };


    const data2 = {
        columns: columns,
        rows: clients
    };
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        resetFormData()
        setIsModalOpen(false);
    };

    const [formData, setFormData] = useState({
        id: '',
        name: '',
        status: '',
        price: ' ',
        days: '',
        couch_sessions: '',

    });
    const resetFormData = () => {
        setFormData({
            id: '',
            name: '',
            status: '',
            price: ' ',
            days: '',
            couch_sessions: '',
        })
    }





    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal}
            >
                Adaugă
            </Button>

            <div style={{ height: '1000px', width: 'auto' }}>
                <DataGrid {...data2} slots={{ toolbar: GridToolbar }} slotProps={{ toolbar: { csvOptions: { allColumns: true } } }} />
            </div>
            <UpsertSubscription
                isModalOpen={isModalOpen}
                formData={formData}
                setFormData={setFormData}
                resetFormData={resetFormData}
                handleCloseModal={handleCloseModal}
                fetchData={fetchData}
            />

            <DeleteSubscription
                isAlertOpen={isAlertOpen}
                deleteId={deleteId}
                fetchData={fetchData}
                resetFormData={resetFormData}
                handleCloseAlertOpen={handleCloseAlertOpen}

            />

        </div>
    );
}

export default Subscriptions;

