import React from 'react'
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";



export default function DeleteClient(props) {

const{isAlertOpen,deleteId,fetchData,resetFormData,handleCloseAlertOpen}=props


const RemoveClient = async () => {
    try {
        const response = await fetch(`https://api.itonit.ro/api/gym/removeClient`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id:deleteId
            })
        });
        if (response.ok) {
            console.log('Datele au fost sterse cu succes');
            fetchData();
        } else {
            console.error('Eroare la stergere datelor');
        }
        resetFormData()
    } catch (error) {
        console.error('Eroare la inserare datelor:', error);
    }

    handleCloseAlertOpen();
};



return (
<div>
    <Modal
        open={isAlertOpen}
        onClose={handleCloseAlertOpen}
        aria-labelledby="add-client-modal"
        aria-describedby="add-client-modal-description"
    >
        <Box
            sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '400px',
                height: 'auto',
                bgcolor: 'white',
                padding: '20px',
                borderRadius: '8px',
                }}
                    >
            <h2 id="add-client-modal" style={{ textAlign: 'center',paddingBottom:'15px' }}>Sunteti sigur ca doriti aceasta actiune ?</h2>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={RemoveClient}
                >
                Sterge
                </Button>

                <Button variant="contained" color="secondary" onClick={handleCloseAlertOpen}>
                    Anuleaza
                </Button>
            </Box>
        </Box>
    </Modal>

    </div>
  )
}

