import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import Button from "@mui/material/Button";
import { Stack } from "@mui/material";
import UpsertClient from "../components/upsertClient";
import DeleteClient from '../components/DeleteClient';


export default function Clients() {
    const [clients, setClients] = useState([]);
    const [deleteId, setDeleteId] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.getItem('isLoggedIn') ? fetchData() : navigate('/');
    }, [navigate]);

    const columns = [
        { field: "name", headerName: 'Nume', flex: 1 },
        { field: "surname", headerName: 'Prenume', flex: 1 },
        { field: "cnp", headerName: 'CNP', flex: 1 },
        { field: "email", headerName: 'Email', flex: 1 },
        { field: "phone", headerName: 'Telefon', flex: 1 },
        {
            field: 'photo',
            headerName: 'Poza',
            flex: 1,
            renderCell: (params) => {
              const imageUrl = params.row.photo ? `https://api.itonit.ro/public/images/${params.row.photo}` : null;
          
              return imageUrl ? (
                <img
                  src={imageUrl}
                  alt={`Poza - ${params.row.photo}`}
                  style={{ width: '100px', height: 'auto' }}
                />
              ) : null;
            },
          },
        {
            field: "subscription_id",
            headerName: 'Abonament',
            flex: 1,
            valueGetter: (params) => params.row.subscriptions ? params.row.subscriptions.name : '',
        },       
        {
            field: 'stauts',
            headerName: 'Status Client',
            flex: 1,
            renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={2}>
                        <p >{params.row.status === '1' ? "Acitv":"Inactiv" }</p>
                    </Stack>
                );
            },
        },
        { field: "subscription_start_date", headerName: 'Start abonament', flex: 1 },
        { field: "subscription_end_date", headerName: 'Stop abonament', flex: 1 },
        { field: "created_at", headerName: 'Data creării', flex: 1 },
        {
            field: 'action',
            headerName: 'Acțiune',
            flex: 1,
            sortable: false,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const onClickEdit = () => {
                    setFormData({
                        ...formData,
                        id: params.row.id,
                        name: params.row.name,
                        surname: params.row.surname,
                        cnp: params.row.cnp,
                        email: params.row.email,
                        phone: params.row.phone,
                        status:params.row.status,
                        subscription_id: params.row.subscription_id,
                        subscription_start_date: params.row.subscription_start_date,
                        couch_id: params.row.couch_id,
                    });
                    handleOpenModal();
                };

                return (
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" color="warning" size="small" onClick={onClickEdit}>Edit</Button>
                    </Stack>
                );
            },
        },
        {
            field: 'delete',
            headerName: 'Status',
            flex: 1,
            sortable: false,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const onClickDelete = () => {
                    setDeleteId(params.row.id)
                    handleAlertOpen()
                };


                return (
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" color="error" size="small" onClick={onClickDelete}>Delete</Button>
                    </Stack>
                );
            },
        },
    ];
    const [isAlertOpen, setIsAlertOpen] = useState(false);

    const handleAlertOpen = () => {
        setIsAlertOpen(true);
    }

    const handleCloseAlertOpen = () => {
        setIsAlertOpen(false);
    }

  

    const fetchData = async () => {
        try {
            const response = await fetch('https://api.itonit.ro/api/gym/getTableClients');
            const data = await response.json();
            setClients(data.data || []);
        } catch (error) {
            console.error('Eroare la preluarea datelor:', error);
        }

    };


    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        resetFormData()
        setIsModalOpen(false);
    };

    const [formData, setFormData] = useState({
        id: '',
        name: '',
        surname: '',
        cnp: '',
        email: '',
        phone: '',
        photo: '',
        status:'',
        subscription_id: '',
        subscription_start_date: '',
        couch_id: ''
    });

    const resetFormData = () => {
        setFormData({
            id: '',
            name: '',
            surname: '',
            cnp: '',
            email: '',
            phone: '',
            photo: '',
            status:'',
            subscription_id: '',
            couch_id: ''
        })
    }
    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal}
            >
                Adaugă
            </Button>
            <div style={{ height: '1000px', width: 'auto' }}>
                <DataGrid columns={columns} rows={clients} components={{ Toolbar: GridToolbar }}  />
            </div>
            <UpsertClient
                isModalOpen={isModalOpen}
                formData={formData}
                setFormData={setFormData}
                resetFormData={resetFormData}
                handleCloseModal={handleCloseModal}
                fetchData={fetchData}
            />

            <DeleteClient
                isAlertOpen={isAlertOpen}
                deleteId={deleteId}
                fetchData={fetchData}
                resetFormData={resetFormData}
                handleCloseAlertOpen={handleCloseAlertOpen}

            />

        </div>
    );
}