import * as React from 'react';
import './style/Menu.css'
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Link } from 'react-router-dom';
import {Collapse} from "@mui/material";
import Box from "@mui/material/Box";

function SideMenu(props) {

    const {showMenu}= props
    return (
        <Box className={`box ${showMenu ? 'active' : ''}`}>
            <Collapse orientation="horizontal" in={showMenu}>
                <div className='side-menu'>
                    <MenuList sx={{width:'100%'}}>
                        <MenuItem sx={{ height: '70px' }}component={Link} to="/checkin" >
                            Checkin
                        </MenuItem>
                        <MenuItem sx={{ height: '70px' }}component={Link} to="/clients">
                            Clienti
                        </MenuItem>
                        <MenuItem sx={{ height: '70px' }}component={Link} to="/subscriptions">
                            Abonamente
                        </MenuItem>
                        <MenuItem sx={{ height: '70px' }}component={Link} to="/calendar">
                            Calendar
                        </MenuItem>
                        <MenuItem sx={{ height: '70px' }}component={Link} to="/settings">
                            Setari
                        </MenuItem>
                    </MenuList>
                </div>
            </Collapse>
        </Box>
    );
}

export default SideMenu;