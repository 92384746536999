import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function Settings() {

    return (
        <TableContainer component={Paper} sx={{ marginLeft: '30px', marginRight: '30px', maxWidth: 'calc(100% - 60px)', textAlign: 'center' }}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell sx={{ width: 'fit-content', textAlign: 'center' }}>Name</TableCell>
                        <TableCell align="right" sx={{ width: 'fit-content', textAlign: 'center' }}>Phone</TableCell>
                        <TableCell align="right" sx={{ width: 'fit-content', textAlign: 'center' }}>Email</TableCell>
                        <TableCell align="right" sx={{ width: 'fit-content', textAlign: 'center' }}>Created At</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                </TableBody>
            </Table>
        </TableContainer>
    );
}
