import React from 'react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";


function upsertSubscription(props) {
    const { isModalOpen, formData, setFormData, resetFormData, handleCloseModal, fetchData } = props
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmitModalEdit = async () => {
        if(!formData.name || !formData.price || !formData.days || !formData.couch_sessions){
            alert("Please fill in all required fields.");
            return;
        }
        try {
            const response = await fetch(`https://api.itonit.ro/api/gym/editSubscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                console.log('Datele au fost actualizate cu succes');
                fetchData();
            } else {
                console.error('Eroare la actualizarea datelor');
            }
            resetFormData()
        } catch (error) {
            console.error('Eroare la actualizarea datelor:', error);
        }

        handleCloseModal();
    };

    const handleSubmitModalAdd = async () => {
        if(!formData.name || !formData.price || !formData.days || !formData.couch_sessions){
            alert("Please fill in all required fields.");
            return;
        }
        try {
            const response = await fetch(`https://api.itonit.ro/api/gym/addSubscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                console.log('Datele au fost inserate cu succes');
                fetchData();
            } else {
                console.error('Eroare la inserare datelor');
            }
            resetFormData()
        } catch (error) {
            console.error('Eroare la inserare datelor:', error);
        }

        handleCloseModal();
    };





    return (
        <div>
            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="add-client-modal"
                aria-describedby="add-client-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '800px',
                        height: '450px',
                        bgcolor: 'white',
                        padding: '20px',
                        borderRadius: '8px',
                    }}
                >
                    <h2 id="add-client-modal" style={{ textAlign: 'center' }}>Adaugă Clienți</h2>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                name="name"
                                label="Nume"
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                value={formData.name}
                                onChange={handleChange}
                                inputProps={{
                                    type: 'text', 
                                    pattern: "[A-Za-z]*", 
                                }}
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^A-Za-z]/g, ''); 
                                }}
                            />
                            <TextField
                                name="price"
                                label="Pret"
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                value={formData.price}
                                onChange={handleChange}
                                inputProps={{
                                    type: 'text', 
                                    pattern: "\\d*", 
                                }}
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^0-9]/g, ''); 
                                }}
                            />
                            <TextField
                                name="days"
                                label="Zile"
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                value={formData.days}
                                onChange={handleChange}
                                inputProps={{
                                    type: 'text', 
                                    pattern: "\\d*", 
                                }}
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^0-9]/g, ''); 
                                }}
                            />


                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name="couch_sessions"
                                label="Zile cu Antrenor"
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                value={formData.couch_sessions}
                                onChange={handleChange}
                                inputProps={{
                                    type: 'text', 
                                    pattern: "\\d*", 
                                }}
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^0-9]/g, ''); 
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            maxWidth: 'calc(100% - 60px)',
                            margin: '0 auto',
                            padding: '10px 30px',
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={formData.id ? handleSubmitModalEdit : handleSubmitModalAdd}
                            sx={{ marginRight: '10px' }}
                        >
                            Save
                        </Button>
                        <Button variant="contained" color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}

export default upsertSubscription
